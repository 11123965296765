import { type FC, useRef, useState, useEffect, useCallback } from "react";
import styles from "./OrderSection.module.scss";
import SideTitleSection from "../common/SideTitleSection/SideTitleSection";
import OrderSectionArticle from "./OrderSectionArticle";
import {
  type OrderSectionDataType,
  orderSectionArticles,
} from "../../data/OrderSectionArticles";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SvgLine } from "./SvgLine";
import { type Point } from "../../utils/points-to-string";
import { TabletWidth, MobileWidth } from "./Consts";
import ApplyForm from "../ApplyForm/ApplyForm";
import Questions from "../QuestionsBlock/Questions/Questions";
import { type QuestionType } from "../../types/QuestionType";
import QuoteBlock from "../common/QuoteBlock/QuoteBlock";
import GoldBorderText from "../common/GoldBorderText/GoldBorderText";

interface DataObject extends OrderSectionDataType {
  index: number;
  textAlignRight: boolean;
}

enum ScreenMode {
  Laptop = 0,
  Tablet,
  Mobile,
  MobileSmall,
}

function detectModeByWindowWidth(windowWidth: number): ScreenMode {
  /* Здесь используем 406 для перехода вместо MobileSmall, 
  потому что на этом разрешении минимальная длина полосы (375) становится равной максимальной (100%-31px)
  и формула расчета разных длин полос в getQuestionWidth уже не подойдет для диапазона ширин окна 375-406.
  */
  if (windowWidth <= 406) return ScreenMode.MobileSmall;

  if (windowWidth <= MobileWidth) return ScreenMode.Mobile;
  if (windowWidth <= TabletWidth) return ScreenMode.Tablet;
  return ScreenMode.Laptop;
}

const OrderSection: FC = () => {
  const convertData = (
    data: OrderSectionDataType,
    index: number,
  ): DataObject => {
    return {
      ...data,
      index,
      textAlignRight: false,
    };
  };

  const windowDimensions = useWindowDimensions();

  const [screenMode, setScreenMode] = useState(
    detectModeByWindowWidth(windowDimensions.width),
  );

  const refTitle = useRef<HTMLHeadingElement | null>(null);
  const [dataModel, setDataModel] = useState<DataObject[]>(
    orderSectionArticles.map(convertData),
  );
  const articleRefs = useRef<Array<HTMLHeadingElement | null>>([]);

  // Минимальное расстояние между линиями
  const [xStep, setXStep] = useState(15);

  // Генерируемая разметка с линиями
  const [jsxListItems, setJsxListItems] = useState<JSX.Element[]>([]);

  // Данные в формате вопрос-ответ для мобильного представления
  const [questions, setQuestions] = useState<QuestionType[]>([]);

  useEffect(() => {
    const newVal = detectModeByWindowWidth(windowDimensions.width);
    if (newVal !== screenMode) setScreenMode(newVal);
  }, [windowDimensions]);

  useEffect(() => {
    let src = orderSectionArticles.map(convertData);

    switch (screenMode) {
      case ScreenMode.Laptop: // Для десктопа первые 2 элемента поменять местами
        src = src.splice(1, 1).concat(src);
        setXStep(20);
        break;

      case ScreenMode.Tablet: // Для планшета вторую половину списка выравнивать вправо
        for (let i = Math.ceil(src.length / 2); i < src.length; i += 1) {
          src[i].textAlignRight = true;
        }
        setXStep(15);
        break;

      case ScreenMode.Mobile:
      default:
        // do nothing
        break;
    }

    setDataModel(src);
  }, [screenMode]);
  // Расчет списка в разметку
  useEffect(() => {
    const existLines = new Array<number>();
    const textStep = 6; // Расстояние от текста
    setJsxListItems(
      dataModel.map((data) => {
        const points = new Array<Point>();
        const curElement = articleRefs.current[data.index];
        const titleElement = refTitle.current;

        if (curElement && titleElement) {
          let x1 =
            (curElement.offsetParent as HTMLElement).offsetLeft +
            curElement.offsetLeft -
            textStep;
          let y1 = curElement.offsetTop + curElement.offsetHeight / 2;

          // Если x1 сдвигается вправо, то y1 надо поднять вверх выше текста
          let yUpped: boolean = false;
          const raiseAboveText = (): void => {
            if (!yUpped) {
              // Поднять можно только 1 раз
              yUpped = true;
              y1 -= curElement.offsetHeight / 2 + textStep;
            }
          };

          // Если x1 левее заголовка, то сдвинем его вправо до заголовка как минимум
          const titleLeft =
            (titleElement.offsetParent as HTMLElement).offsetLeft +
            titleElement.offsetLeft;
          if (x1 <= titleLeft - textStep) {
            x1 = titleLeft;
            raiseAboveText();
          }

          // Пока есть отрезки наложенные рядом, сдвигаем по оси X вправо
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          while (existLines.some((val) => Math.abs(val - x1) < xStep)) {
            x1 += xStep;
            raiseAboveText();
          }
          existLines.push(x1);

          points.push({ x: x1, y: y1 });

          const x2 = x1;
          let y2 = titleElement.offsetTop + titleElement.offsetHeight;

          let needBend = false;
          const titleRight =
            (titleElement.offsetParent as HTMLElement).offsetLeft +
            titleElement.offsetLeft +
            titleElement.offsetWidth;
          if (x2 >= titleRight + textStep) {
            y2 -= titleElement.offsetHeight / 2;
            needBend = true;
          }
          points.push({ x: x2, y: y2 });

          if (needBend) {
            const y3 = y2;
            const x3 = titleRight + textStep;
            points.push({ x: x3, y: y3 });
          }
        }

        return (
          <li className={styles.orderSection__item} key={data.index}>
            <OrderSectionArticle
              title={data.title}
              text={data.text}
              preLine={data.preLine ?? false}
              textAlignRight={data.textAlignRight}
              refItemTitle={(el) => (articleRefs.current[data.index] = el)}
            />
            <SvgLine
              points={points}
              circle={points.length > 0 ? points[0] : null}
            />
          </li>
        );
      }),
    );
  }, [windowDimensions, dataModel, xStep]);

  useEffect(() => {
    setQuestions(
      orderSectionArticles.map((data, index) => {
        return {
          index,
          question: data.title,
          answer: data.text,
        };
      }),
    );
  }, []); // Один раз вычисляем

  const getQuestionWidth = useCallback(
    (question: QuestionType): string => {
      if (screenMode === ScreenMode.MobileSmall) {
        return "calc(100% - 15px)"; // Обязательны пробелы вокруг плюса, иначе неверно считает
      }

      const count = questions.length; // всего вопросов и соответсвенно полос
      const isEven = count % 2 === 0; // четное число?
      const middleIndex = isEven ? count / 2 - 1 : Math.floor(count / 2); // Индекс серединного элемента с самой длинной полосой (или первый из них для четного числа)
      const min = 375; // Минимальная длина полосы
      const indentRight = 31; // Минимальный отступ справа для полосы
      const mirroredIndex = // Зеркальный индекс, для 0,1,2,3,4 будет 0,1,2,1,0
        question.index <= middleIndex
          ? question.index
          : count - question.index - 1;

      /*
      // Полосы равномерно (с равным шагом) растут от минимальной длины
      // до максимальной в середине списка и затем также уменьшаются.
      // Минимальная длина полосы задана переменной min
      // Максимальная зависит от ширины окна и равна 100% - indentRight
      // Обязательны пробелы вокруг операций, иначе неверно считает
      */
      return `calc(${min}px + (100% - ${min}px - ${indentRight}px) * ${mirroredIndex} / ${middleIndex} )`;
    },
    [screenMode, questions],
  );

  let quoteComponent;
  if (windowDimensions.width <= 500) {
    quoteComponent = (
      <h2 className={styles.orderSection__quote2}>
        <GoldBorderText
          style={{
            padding: windowDimensions.width <= 450 ? "10px 8px" : "10px 35px",
          }}
          text="Или вернем деньги"
        />
      </h2>
    );
  } else if (windowDimensions.width <= 1024) {
    quoteComponent = (
      <h2 className={styles.orderSection__quote2}>
        Или <GoldBorderText style={{ padding: "0px 8px" }} text="вернем" />{" "}
        деньги
      </h2>
    );
  } else {
    quoteComponent = (
      <h2 className={styles.orderSection__quote2}>
        Или <GoldBorderText style={{ padding: "0px 8px" }} text="вернем" />{" "}
        деньги
      </h2>
    );
  }

  return (
    <section className={styles.orderSection} id="order">
      <SideTitleSection
        title="ОСТАВИТЬ ЗАЯВКУ"
        titleClassName={styles.orderSection__sideTitle}
      >
        <div className={styles.orderSection__basisInstallation}>
          <h3
            className={styles.orderSection__basisInstallationTitle}
            ref={refTitle}
          >
            Базовая сборка
          </h3>

          {screenMode !== ScreenMode.Mobile &&
          screenMode !== ScreenMode.MobileSmall ? (
            <ul className={styles.orderSection__list}>
              {jsxListItems}
              <div className={styles.orderSection__formContainer}>
                <ApplyForm />
              </div>
            </ul>
          ) : (
            <>
              <Questions
                questions={questions}
                getQuestionWidth={getQuestionWidth}
                customQuestionsClass={styles.orderSection__questions}
                customQuestionTitleClass={styles.orderSection__questionTitle}
                customAnswerClass={styles.orderSection__answer}
                alwaysGoldLines
                hasHighlightAnimation
              />
              <div className={styles.orderSection__formContainer}>
                <ApplyForm />
              </div>
            </>
          )}
        </div>
      </SideTitleSection>
      <div className={styles.orderSection__footer} />
      <QuoteBlock whiteTheme={windowDimensions.width > 500}>
        <h2 className={styles.orderSection__quote1}>
          Акустическая система, <br /> которая вас радует
        </h2>
      </QuoteBlock>
      <QuoteBlock>{quoteComponent}</QuoteBlock>
    </section>
  );
};

export default OrderSection;
