import { type FC } from "react";
import styles from "./Contacts.module.scss";
import TelegramIcon from "../../assets/icons/TelegramIcon";
import VkIcon from "../../assets/icons/VkIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import GoldBorderTitle from "../common/GoldBorderTitle/GoldBorderTitle";

const arcticusEmail = "arcticus.sound@gmail.com";

const Contacts: FC = () => {
  return (
    <div className={styles.contacts}>
      <div className={styles.contacts__headerContainer}>
        <GoldBorderTitle
          title="КОНТАКТЫ"
          headerClassName={styles.contacts__header}
        />
      </div>
      <a
        className={styles.contacts__email}
        href={`mailto:${arcticusEmail}&subject=обратная связь с сайта`}
      >
        {arcticusEmail}
      </a>
      <ul className={styles.contacts__links}>
        <li className={styles.contacts__listitem}>
          <a
            className={styles.contacts__link}
            href="https://t.me/ARTICUS_SOUND"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon className={styles.contacts__linkimage} />
          </a>
        </li>
        <li className={styles.contacts__listitem}>
          <a
            className={styles.contacts__link}
            href="https://vk.com/club223807475"
            target="_blank"
            rel="noreferrer"
          >
            <VkIcon className={styles.contacts__linkimage} />
          </a>
        </li>
        <li className={styles.contacts__listitem}>
          <a
            className={styles.contacts__link}
            href="https://instagram.com/articus.sound.arc"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon className={styles.contacts__linkimage} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Contacts;
