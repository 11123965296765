/* eslint-disable @typescript-eslint/explicit-function-return-type */
import emailjs from "@emailjs/browser";

const formDataSend = async () => {
  try {
    const result = await emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID as string,
      process.env.REACT_APP_TEMPLATE_ID as string,
      "#requestForm",
      process.env.REACT_APP_PUBLIC_KEY as string,
    );
    return result;
  } catch (error) {
    return error;
  }
};

export default formDataSend;
