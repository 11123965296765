import { type FC } from "react";
import { type TTextType } from "../Product.types";
import styles from "./Text.module.scss";

interface ITextProps {
  type: TTextType;
  isSelected: boolean;
  isMobile: boolean;
  children: React.ReactNode;
}

const Text: FC<ITextProps> = ({ type, isSelected, isMobile, children }) => {
  let className = `${styles.text} ${styles[`text_type_${type}`]}`;

  if (isMobile)
    className += isSelected
      ? ` ${styles.text_visibility_visible}`
      : ` ${styles.text_visibility_hidden}`;

  return <p className={className}>{children}</p>;
};

export default Text;
