import { type FC, useState, useEffect, useRef, Fragment, useMemo } from "react";
import style from "./Footer.module.scss";
import { Logo, MenuIco } from "../../assets/icons";
import MENU_ITEMS from "../../data/Menu-items";
import cn from "../../utils/cn";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useOutsideClick from "../../hooks/useOutside";

// const switchToBigDesktopRes = 1800;
const switchToDesktopRes = 1260;
const switchToMobileRes = 500;

const Footer: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuIcoActive, setMenuIcoActive] = useState<boolean>(true);
  const { width } = useWindowDimensions();

  const burgerScreenWidth: boolean = useMemo(
    () => width <= switchToDesktopRes,
    [width],
  );
  const mobileScreenWidth: boolean = useMemo(
    () => width <= switchToMobileRes,
    [width],
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const refsToCheck = [ref];

  useOutsideClick(refsToCheck, () => {
    if (menuOpen) {
      closeMenu();
    }
  });

  const openMenu = (): void => {
    if (menuIcoActive) {
      setMenuOpen(true);
      setMenuIcoActive(false);
    }
  };

  const closeMenu = (): void => {
    setMenuOpen(false);
  };

  useEffect((): void => {
    if (mobileScreenWidth) closeMenu();
  }, [width, mobileScreenWidth]);

  useEffect(() => {
    if (!menuOpen) {
      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        setMenuIcoActive(true);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [menuOpen]);

  return (
    <footer className={style.footer}>
      <div className={style.footer__container}>
        <h2 className={style.footer__slogan}>
          {width > switchToDesktopRes
            ? "НАЧНИТЕ СОЗДАВАТЬ\nЗВУКОВОЕ ИСКУССТВО\nC "
            : "СОЗДАВАЙТЕ\nИСКУССТВО\nC "}
          <span className={style.footer__slogan_accent}>ARC</span>
        </h2>
        <div className={style.footer__bottom_content}>
          <div className={style.footer__logo_section}>
            <a href="/" className={style.footer__logo_link}>
              <Logo className={style.footer__logo_ico} />
              <h2 className={style.footer__logo_title}>
                custom acoustic system
              </h2>
            </a>
            <span className={style.footer__logo_title}>
              &{" "}
              <a
                href="https://www.behance.net/fb621071"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline", color: "inherit" }}
              >
                kleksened
              </a>
            </span>
          </div>
          <div
            className={style.footer__menu}
            onMouseEnter={() => {
              if (!burgerScreenWidth && menuIcoActive) {
                openMenu();
              }
            }}
            onClick={() => {
              menuIcoActive && openMenu();
            }}
            aria-hidden="true"
            ref={ref}
          >
            <div
              className={cn(
                "menu",
                burgerScreenWidth
                  ? style.footer__menu_burger
                  : style.footer__menu_block,
                menuOpen &&
                  (burgerScreenWidth
                    ? style.footer__menu_burger_visible
                    : style.footer__menu_block_visible),
              )}
            >
              {MENU_ITEMS.map((el, i) => (
                <Fragment key={el.id}>
                  <a
                    href={el.link}
                    className={style.footer__menu_link}
                    onClick={closeMenu}
                  >
                    {el.name}
                  </a>
                  {burgerScreenWidth && (
                    <div className={style.footer__menu_burger_line} />
                  )}
                </Fragment>
              ))}
              {burgerScreenWidth && (
                <button
                  type="button"
                  className={style.footer__menu_burger_button}
                  aria-label="закрыть меню"
                  onClick={closeMenu}
                >
                  <MenuIco
                    className={cn(
                      style.footer__menu_ico,
                      style.footer__menu_ico_burger,
                    )}
                  />
                </button>
              )}
            </div>
            {!mobileScreenWidth && (
              <div className={style.footer__menu_container}>
                <MenuIco className={style.footer__menu_ico} />
                <span
                  className={cn(
                    style.footer__menu_text,
                    menuOpen ? style.footer__menu_text_invisible : "",
                  )}
                >
                  меню
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
