export interface Point {
  x: number;
  y: number;
}

export function pointsToString(points: Point[]): string {
  return points.reduce((currentResult, value) => {
    return `${currentResult} ${value.x},${value.y}`;
  }, "");
}
