import { useEffect, type RefObject } from "react";

export default function useOutsideClick(
  refs: Array<RefObject<HTMLElement> | undefined>,
  handler?: () => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!handler) return;

      if (event.clientX >= document.documentElement.offsetWidth) return;

      let containedToAnyRefs = false;
      refs.forEach((rf) => {
        if (rf?.current && rf.current.contains(event.target as HTMLElement)) {
          containedToAnyRefs = true;
        }
      });

      if (!containedToAnyRefs) {
        handler();
      }
    }

    function handleKeyPress(event: KeyboardEvent): void {
      if (event.key === "Escape" && handler) {
        handler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [refs, handler]);
}
