import { type FC } from "react";
import styles from "./Home.module.scss";
import About from "../../components/About/About";
import Header from "../../components/Header/Header";
import QuestionsBlock from "../../components/QuestionsBlock/QuestionsBlock";
import ContactsSection from "../../components/Contacts/ContactsSection";
import Footer from "../../components/Footer/Footer";
import Product from "../../components/Product/Product";
import OrderSection from "../../components/Order/OrderSection";
import Wave from "../../components/Wave/Wave";
import Gallery from "../../components/Gallery/Gallery";
import images from "../../data/gallery";

const Home: FC = () => {
  return (
    <div className={styles.home}>
      <Header />
      <About />
      <Product />
      <OrderSection />
      <Wave />
      <Gallery images={images} />
      <ContactsSection />
      <QuestionsBlock />
      <Footer />
    </div>
  );
};

export default Home;
