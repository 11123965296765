const questions = [
  {
    index: 1,
    line: "63%",
    tablet_line: "82.8%",
    question: "Как оформить заказ кастомной акустики?",
    answer:
      "Вам необходимо заполнить форму и с вами обязательно свяжется наш специалист, который поможет разработать дизайн акустики и уточнит все ваши пожелания по характеристикам акустики",
  },
  {
    index: 2,
    line: "77.51%",
    tablet_line: "92%",
    question: "Какой срок изготовления акустической системы?",
    answer:
      "Срок изготовления системы зависит от сложности дизайна. Среднее выполнение акустики один месяц",
  },
  {
    index: 3,
    line: "89%",
    tablet_line: "100%",
    question: "Какие компоненты используются при реализации?",
    answer:
      "Наши специалисты заказывают специализированные компоненты, которые обладают высокими характеристиками и выдают звук превосходного качества",
  },
  {
    index: 4,
    line: "100%",
    tablet_line: "100%",
    question: "Как происходит реализация корпуса?",
    answer:
      "Наши специалисты проектируют корпус, который обеспечивает впечатляющее и теплое звучание, \n" +
      "а наличие фазоинвертора  позволяет воспроизводить глубокие басы. Использование демпфирования дает возможность поглощать нежелательную звуковую энергию",
  },
  {
    index: 5,
    line: "89%",
    tablet_line: "92%",
    question: "Как будет работать акустическая система?",
    answer:
      "Акустика имеет собственный встроенный усилитель и кроссоверы, вам достаточно только включить ее в розетку и наслаждаться звуком",
  },
  {
    index: 6,
    line: "76.84%",
    tablet_line: "81.67%",
    question: "Какими способами можно подключиться к системе?",
    answer:
      "К системе можно подключится любым удобным в вас способом. Система поддерживает Bluetooth, а также имеет самые распространенные входы и выходы, чтобы у вас не возникло никаких проблем с подключением",
  },
];

export default questions;
