import { type FC } from "react";

interface MenuIcoProps {
  className?: string;
}

const MenuIco: FC<MenuIcoProps> = ({ className }) => (
  <svg
    width="93"
    height="120"
    viewBox="0 0 93 120"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_403_14" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C17.0751 0 22 4.92486 22 11V109C22 115.075 17.0751 120 11 120C4.92487 120 0 115.075 0 109V11C0 4.92487 4.92487 0 11 0ZM58 11C58 4.92487 53.0751 0 47 0C40.9249 0 36 4.92487 36 11V73C36 79.0751 40.9249 84 47 84C53.0751 84 58 79.0751 58 73V11ZM93 11C93 4.92487 88.0751 0 82 0C75.9249 0 71 4.92487 71 11V67C71 73.0751 75.9249 78 82 78C88.0751 78 93 73.0751 93 67V11Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C17.0751 0 22 4.92486 22 11V109C22 115.075 17.0751 120 11 120C4.92487 120 0 115.075 0 109V11C0 4.92487 4.92487 0 11 0ZM58 11C58 4.92487 53.0751 0 47 0C40.9249 0 36 4.92487 36 11V73C36 79.0751 40.9249 84 47 84C53.0751 84 58 79.0751 58 73V11ZM93 11C93 4.92487 88.0751 0 82 0C75.9249 0 71 4.92487 71 11V67C71 73.0751 75.9249 78 82 78C88.0751 78 93 73.0751 93 67V11Z"
      fill="#E0A51F"
    />
    <path
      d="M27 11C27 2.16344 19.8366 -5 11 -5V5C14.3137 5 17 7.68629 17 11H27ZM27 109V11H17V109H27ZM11 125C19.8366 125 27 117.837 27 109H17C17 112.314 14.3137 115 11 115V125ZM-5 109C-5 117.837 2.16344 125 11 125V115C7.68629 115 5 112.314 5 109H-5ZM-5 11V109H5V11H-5ZM11 -5C2.16344 -5 -5 2.16344 -5 11H5C5 7.68629 7.68629 5 11 5V-5ZM47 5C50.3137 5 53 7.68629 53 11H63C63 2.16344 55.8366 -5 47 -5V5ZM41 11C41 7.68629 43.6863 5 47 5V-5C38.1634 -5 31 2.16345 31 11H41ZM41 73V11H31V73H41ZM47 79C43.6863 79 41 76.3137 41 73H31C31 81.8366 38.1634 89 47 89V79ZM53 73C53 76.3137 50.3137 79 47 79V89C55.8366 89 63 81.8366 63 73H53ZM53 11V73H63V11H53ZM82 5C85.3137 5 88 7.68629 88 11H98C98 2.16345 90.8366 -5 82 -5V5ZM76 11C76 7.68629 78.6863 5 82 5V-5C73.1634 -5 66 2.16345 66 11H76ZM76 67V11H66V67H76ZM82 73C78.6863 73 76 70.3137 76 67H66C66 75.8365 73.1634 83 82 83V73ZM88 67C88 70.3137 85.3137 73 82 73V83C90.8366 83 98 75.8366 98 67H88ZM88 11V67H98V11H88Z"
      fill="#E0A51F"
      mask="url(#path-1-inside-1_403_14)"
    />
  </svg>
);

MenuIco.defaultProps = { className: "" };

export default MenuIco;
