interface OrderSectionDataType {
  title: string;
  text: string;
  preLine?: boolean;
}

const orderSectionArticles: OrderSectionDataType[] = [
  {
    title: "индивидуальный дизайн",
    text: "Начинаем с создания уникального дизайна, который включает пожелания",
  },
  {
    title: "эксклюзивный корпус",
    text: "Является настоящим произведением искусства. Выбирайте материалы для корпуса: МДФ, ДСП или массив дерева",
  },
  {
    title: "декорирование",
    text: "Выбирайте отделку: оклейка корпуса виниловой пленкой, трафаретная печать, цифровая печать на УФ принтере, выжигание, гравирование, добавление элементов из эпоксидной смолы и декоративной подсветки",
  },
  {
    title: "исключительные динамики и компоненты",
    text: `В образце используются профессиональные динамики и компоненты, со следующими характеристиками:
    Динамики СЧ/НЧ сопротивление 8 Ом, чувствительность 91 дБ, номинальная мощность 60 Вт, музыкальная мощность 120 Вт;
    Динамики ВЧ сопротивление 8 Ом, чувствительность 90.7 дБ, номинальная мощность 50 Вт, музыкальная мощность 100 Вт;
    Усилитель класса D мощностью 2x 50 Вт Кроссоверы пассивные двухполосные`,
    preLine: true, // Необходимо сохранить явно заданные в тексте переносы строк
  },
  {
    title: "гарантия уникальности и качества",
    text: "Ручная сборка ведется с максимальной внимательностью к деталям, стыкам, креплениям для надежности и продолжительного срока эксплуатации",
  },
];

export { type OrderSectionDataType, orderSectionArticles };
