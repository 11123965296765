import { type ReactNode, type CanvasHTMLAttributes } from "react";
import useCanvas from "../../../hooks/useCanvas";

export type TCanvas = {
  draw: (
    ctx: CanvasRenderingContext2D,
    frame: number,
    waveStart: number,
    waveWidth: number,
    waveHeight: number,
    horizontal: boolean,
  ) => void;
  waveStart: number;
  waveWidth: number;
  waveHeight: number;
  horizontal: boolean;
  isOn: boolean;
} & CanvasHTMLAttributes<HTMLCanvasElement>;

const CanvasWithWave = (props: TCanvas): ReactNode => {
  const {
    draw,
    className,
    waveStart,
    waveWidth,
    waveHeight,
    horizontal,
    isOn,
    ...rest
  } = props;

  const ref = useCanvas(
    draw,
    waveStart,
    waveWidth,
    waveHeight,
    horizontal,
    isOn,
  );

  return <canvas ref={ref} {...rest} />;
};

export default CanvasWithWave;
