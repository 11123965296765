import img0 from "../assets/images/Gallery/Full/2.png";
import img1 from "../assets/images/Gallery/Full/3.png";
import img2 from "../assets/images/Gallery/Full/4.png";
import img3 from "../assets/images/Gallery/Full/5.png";

const images = [
  { image: img0, id: 0 },
  { image: img1, id: 1 },
  { image: img2, id: 2 },
  { image: img3, id: 3 },
];

export default images;
