export enum ProductType {
  BUILD = "build",
  DESIGN = "design",
  SOUND = "sound",
  ABSORPTION = "absorption",
  COMPONENTS = "components",
}

export type TTextType = ProductType | undefined;

export enum CircleColor {
  ORDINARY = "white",
  SELECTED = "black",
}

export enum CirclePosition {
  LEFT = "left",
  RIGHT = "right",
}

export enum ScreenSize {
  MOBILE = 500,
  TABLET = 1024,
}
