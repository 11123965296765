import { useEffect, type FC, useState } from "react";
import styles from "./SvgLine.module.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { TabletWidth } from "./Consts";
import { type Point, pointsToString } from "../../utils/points-to-string";

export interface SvgLineProps {
  points: Point[];
  circle?: Point | null;
}

export const SvgLine: FC<SvgLineProps> = ({ points, circle }) => {
  const windowDimensions = useWindowDimensions();
  const [viewBox, setViewBox] = useState("0 0 0 0");
  const [strokeWidth, SetStrokeWidth] = useState<number>(0);
  const [radius, SetRadius] = useState<number>(0);

  useEffect(() => {
    setViewBox(`0 0 ${windowDimensions.width} ${windowDimensions.height}`);
    SetStrokeWidth(windowDimensions.width > TabletWidth ? 3 : 2);
    SetRadius(windowDimensions.width > TabletWidth ? 7 : 4);
  }, [windowDimensions]);

  return (
    <svg
      className={styles.svgLine}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points={pointsToString(points)}
        fill="none"
        strokeWidth={strokeWidth}
        stroke="black"
      />
      {circle ? <circle cx={circle.x} cy={circle.y} r={radius} /> : ""}
    </svg>
  );
};

SvgLine.defaultProps = { circle: null };
