import { type FC } from "react";
import styles from "./Gallery.module.scss";
import Slider from "../common/Slider/Slider";
import SideTitleSection from "../common/SideTitleSection/SideTitleSection";
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface GalleryProps {
  images: Array<{ image: string; id: number }>;
}

const Gallery: FC<GalleryProps> = ({ images }) => {
  const { width } = useWindowDimensions();
  return (
    <section className={styles.gallery} id="gallery">
      <SideTitleSection
        title="галерея"
        titleClassName={width <= 500 ? styles.gallery__sideTitle : ""}
      >
        <div className={styles.gallery__gradient} />
        <Slider images={images} />
      </SideTitleSection>
    </section>
  );
};

export default Gallery;
