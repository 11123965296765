/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import { useForm, type SubmitHandler, type FieldErrors } from "react-hook-form";
import { type FC, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import styles from "./ApplyForm.module.scss";
import cn from "../../utils/cn";
import formDataSend from "../../utils/formDataSend";
import consts from "./consts";

interface TFormData {
  name: string;
  email: string;
  tel: string;
  file?: File[];
  textarea?: string;
}

interface ErrorProps {
  errors: FieldErrors<TFormData>;
  name: string;
}

const Error: FC<ErrorProps> = ({ errors, name }) => {
  const classNames = `${styles.form__error} ${
    errors[name as keyof typeof errors]?.message
      ? styles.form__error_active
      : ""
  }`;

  return (
    <p className={classNames}>
      <ErrorMessage errors={errors} name={name} />
    </p>
  );
};

const ApplyForm = (): JSX.Element => {
  const [submitButtonActive, setSubmitButtonActive] = useState<boolean>(true);
  const [submitButtonText, setSubmitButtonText] = useState<string>(
    consts.submitDefault,
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<TFormData>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<TFormData> = () => {
    setSubmitButtonText(consts.submitAwait);
    setSubmitButtonActive(false);
    formDataSend()
      .then((result: any) => {
        if (result.status === 200) {
          setSubmitButtonText(consts.submitSuccess);
        } else {
          setSubmitButtonText(consts.submitError);
        }
        setTimeout(() => {
          setSubmitButtonActive(true);
          setSubmitButtonText(consts.submitDefault);
          reset();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      id="requestForm"
    >
      <div className={styles.form__container}>
        <div className={styles.form__wrapper}>
          <input
            className={styles.form__inputs}
            autoComplete="new-password"
            {...register("name", {
              required: "Заполните ФИО",
              maxLength: {
                message: "Введите не более 100 символов в поле ФИО",
                value: 100,
              },
            })}
            placeholder="ФИО*"
          />
        </div>
        <Error errors={errors} name="name" />

        <div className={styles.form__wrapper}>
          <input
            className={styles.form__inputs}
            type="email"
            autoComplete="new-password"
            {...register("email", {
              required: "Заполните email",
              pattern: {
                message: "Некорректный email",
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/,
              },
            })}
            placeholder="E-MAIL*"
          />
        </div>
        <Error errors={errors} name="email" />

        <div className={styles.form__wrapper}>
          <input
            className={styles.form__inputs}
            type="tel"
            autoComplete="new-password"
            {...register("tel", {
              required: "Заполните номер телефона",
              pattern: {
                message: "Некорректный номер телефона",
                value: /^\+[0-9]{11}$/,
              },
            })}
            placeholder="НОМЕР ТЕЛЕФОНА*"
          />
        </div>
        <Error errors={errors} name="tel" />

        <div className={styles.form__wrapper}>
          <textarea
            wrap="soft"
            rows={6}
            {...register("textarea", {
              maxLength: {
                message: "Длина сообщения не более 1000 символов",
                value: 1000,
              },
            })}
            className={cn(
              styles.form__inputs,
              styles.form__inputs_type_textarea,
            )}
            placeholder="КОММЕНТАРИЙ ( СВЯЖИТЕСЬ 
            СО МНОЙ В ТАКОЕ-ТО ВРЕМЯ...)"
          />
        </div>
        <Error errors={errors} name="textarea" />
      </div>
      <button
        type="submit"
        className={`${styles.form__submit} ${
          submitButtonActive ? "" : styles.form__submit_sended
        }`}
        disabled={!submitButtonActive || !isValid}
      >
        {submitButtonText}
      </button>
    </form>
  );
};

export default ApplyForm;
