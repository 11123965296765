const MENU_ITEMS = [
  {
    name: "ГЛАВНАЯ",
    link: "#header",
    id: 1,
  },
  {
    name: "О НАС",
    link: "#about",
    id: 2,
  },
  {
    name: "ПРОДУКТ",
    link: "#product",
    id: 3,
  },
  {
    name: "ОСТАВИТЬ ЗАЯВКУ",
    link: "#order",
    id: 4,
  },
  {
    name: "ГАЛЕРЕЯ",
    link: "#gallery",
    id: 5,
  },
  {
    name: "КОНТАКТЫ",
    link: "#contacts",
    id: 6,
  },
  {
    name: "FAQ",
    link: "#FAQ",
    id: 7,
  },
];

export default MENU_ITEMS;
