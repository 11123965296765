import styles from "./Consts.module.scss";

// Извлекаем число пикселей числом без букв 'px' итп
function getNumber(str: string): number {
  const matchResult = str.match(/^\d+/);
  return matchResult ? Number(matchResult[0]) : 0;
}

const {
  Tablet: tabletStr,
  Mobile: mobileStr,
  MobileSmall: mobileSmallStr,
} = styles;

const TabletWidth = getNumber(tabletStr);
const MobileWidth = getNumber(mobileStr);
const MobileSmallWidth = getNumber(mobileSmallStr);

export { TabletWidth, MobileWidth, MobileSmallWidth };
