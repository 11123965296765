import { type FC, useState } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { ScreenSize } from "./Slider.types";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from "./Slider.module.scss";

interface SliderProps {
  images: Array<{ image: string; id: number }>;
}

const Slider: FC<SliderProps> = ({ images }) => {
  const { width } = useWindowDimensions();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [switchTimer] = useState<number>(
    width <= ScreenSize.TABLET && width > ScreenSize.MOBILE ? 5000 : 3000, // переключение слайдов на планшетном разрешении 5с, на остальных 3с
  );
  const sleepTimer: number = 6000;
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel);
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseSleepTimeout: ReturnType<typeof setTimeout>;

        function clearNextTimeout(): void {
          clearTimeout(timeout);
        }

        function nextTimeout(): void {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, switchTimer); // установка таймера переключения картинок
        }

        function nextMouseSleepTimeout(): void {
          clearTimeout(mouseSleepTimeout);
          mouseSleepTimeout = setTimeout(() => {
            setMouseOver(false);
            nextTimeout();
          }, sleepTimer); // установка таймера неактивного курсора
        }

        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            setMouseOver(true);
            clearNextTimeout();
            slider.container.addEventListener("mousemove", () => {
              clearNextTimeout();
              nextMouseSleepTimeout();
            });
          });
          slider.container.addEventListener("mouseout", () => {
            setMouseOver(false);
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", () => {
          nextTimeout();
        });
        slider.on("updated", () => {
          nextTimeout();
        });
      },
    ],
  );
  // создание массива кнопок по кол-ву изображений
  const buttons = images.map((image, index: number) => {
    return (
      <button
        className={
          currentSlide === index // условие для трансформации активной кнопки
            ? `${styles.slider__button} ${styles.slider__button_active}`
            : styles.slider__button
        }
        type="button"
        key={image.id}
        onClick={() => {
          instanceRef.current?.moveToIdx(index);
        }}
        aria-label="переключение слайда"
      />
    );
  });
  // создание массива изображений для слайдера
  const imageElements = images.map((image, index: number) => {
    return (
      <img
        className="keen-slider__slide"
        src={images[index].image}
        alt="изображение колонки"
        key={image.id}
      />
    );
  });
  return (
    <div className={styles.slider}>
      <div ref={sliderRef} className="keen-slider">
        {imageElements}
      </div>
      <div className={styles.slider__menu}>{buttons}</div>
    </div>
  );
};

export default Slider;
