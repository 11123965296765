import { type FC } from "react";
import SideTitleSection from "../common/SideTitleSection/SideTitleSection";
import styles from "./About.module.scss";
import QuoteBlock from "../common/QuoteBlock/QuoteBlock";

const About: FC = () => {
  return (
    <section className={styles.about} id="about">
      <QuoteBlock sectionClassName={styles.about__quote}>
        ARC – ваш проводник <br /> в мир звука и стиля
      </QuoteBlock>
      <SideTitleSection title="О нас">
        <div className={styles.about__info}>
          <p
            className={`${styles.about__text} ${styles.about__text_type_left}`}
          >
            <span className={styles.about__text__border}>
              Наша акустика — это слияние дизайна и безукоризненного качества
              звука. Технологический процесс начинается с создания дизайна,
              изготовления корпуса, закупки высококачественных динамиков у
              надежных производителей
            </span>
          </p>
          <p
            className={`${styles.about__text} ${styles.about__text_type_right}`}
          >
            <span className={styles.about__text__border}>
              Специалисты рассчитывают фильтры и электронику, после чего
              происходит сборка и установка компонентов. Каждый этап
              производства контролируется с особым вниманием, через
              неоднократную проверку результатов, что гарантирует качество
              нашего продукта
            </span>
          </p>
        </div>
      </SideTitleSection>
    </section>
  );
};

export default About;
