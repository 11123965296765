import { type Ref, useState, useEffect, useRef } from "react";

const useCanvas = (
  drawWave: (
    ctx: CanvasRenderingContext2D,
    frame: number,
    waveStart: number,
    waveWidth: number,
    waveHeight: number,
    horizontal: boolean,
  ) => void,
  waveStart: number,
  waveWidth: number,
  waveHeight: number,
  horizontal: boolean,
  isOn: boolean,
): Ref<HTMLCanvasElement> => {
  const ref = useRef<HTMLCanvasElement>(null);
  // шаг счётчика градусов (отвечает за скорость волны)
  let step: number;
  if (horizontal) step = 0.5;
  else if (waveWidth >= 400) step = 1.4;
  else step = 1;

  const [dAngle, setDAngle] = useState(0);

  useEffect(() => {
    const canvas: HTMLCanvasElement | null = ref.current;
    const ctx: CanvasRenderingContext2D | null = canvas
      ? canvas.getContext("2d")
      : null;
    let degAngle: number = dAngle;
    let animationID: number;

    const renderer = (): void => {
      if (degAngle > 360) {
        degAngle = 0;
      }

      if (isOn || horizontal) {
        degAngle += step;
        setDAngle(degAngle);
      }

      if (ctx)
        drawWave(ctx, degAngle, waveStart, waveWidth, waveHeight, horizontal);
      animationID = window.requestAnimationFrame(renderer);
    };

    renderer();
    return () => {
      window.cancelAnimationFrame(animationID);
    };
  }, [drawWave, waveStart, waveWidth, isOn]);

  return ref;
};

export default useCanvas;
