import React, { type FC, useEffect, useState } from "react";
import style from "./Wave.module.scss";
import useYOffset from "../../hooks/useYOffset";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CanvasWithWave from "./CanvasWithWave/CanvasWithWave";
import drawWave from "../../utils/drawWave";

const Wave: FC = () => {
  const { yOffset } = useYOffset();
  const { width } = useWindowDimensions();

  const [waveYOffset, setWaveYOffset] = useState(0);
  const [waveWidth, setWaveWidth] = useState(0);
  const [waveHeight, setWaveHeight] = useState(0);
  const [waveStartOffset, setWaveStartOffset] = useState(300);
  const [isOn, setIsOn] = useState(true);
  const [screenType, setScreenType] = useState<
    "Laptop" | "Tablet" | "Mobile" | null
  >(null);

  useEffect(() => {
    if (width > 1300) {
      setWaveWidth(400);
      setWaveHeight(300);
      setWaveStartOffset(400);
      setScreenType("Laptop");
    } else if (width > 600) {
      setWaveWidth(200);
      setWaveHeight(200);
      setWaveStartOffset(300);
      setScreenType("Tablet");
    } else {
      setWaveWidth(500);
      setWaveHeight(width);
      setWaveStartOffset(0);
      setScreenType("Mobile");
    }
    // eslint-disable-next-line
  }, [, width]);

  useEffect((): any => {
    let timeOut: number;

    if (!isOn) {
      timeOut = window.setTimeout(() => {
        setIsOn(true);
      }, 1500);
      return () => {
        window.clearTimeout(timeOut);
      };
    }
    return undefined;
  }, [isOn]);

  useEffect(() => {
    // начальное текущего смещение положения блока с волной от начала страницы в зависимости от скролла
    const yRate = waveStartOffset + yOffset;
    setWaveYOffset(yRate);
    if (isOn) setIsOn(false);
  }, [yOffset]);

  return (
    <div
      className={
        screenType === "Mobile"
          ? style.wave__container_mobile
          : style.wave__container
      }
    >
      <CanvasWithWave
        draw={drawWave}
        waveStart={waveYOffset}
        waveWidth={waveWidth}
        waveHeight={waveHeight}
        width={document.body.scrollWidth}
        height={screenType === "Mobile" ? 500 : document.body.scrollHeight}
        horizontal={screenType === "Mobile"}
        isOn={isOn}
      />
    </div>
  );
};

export default Wave;
