import { type FC } from "react";
import { CirclePosition } from "../Product.types";
import { type Point, pointsToString } from "../../../utils/points-to-string";
import styles from "./Line.module.scss";

interface ILineProps {
  points: Point[];
  circlePosition: CirclePosition;
  isLaptop: boolean;
}

const Line: FC<ILineProps> = ({ points, circlePosition, isLaptop }) => {
  const radius = isLaptop ? 7 : 4;

  return (
    <svg className={styles.line} xmlns="http://www.w3.org/2000/svg">
      <polyline
        points={pointsToString(points)}
        fill="none"
        strokeWidth={isLaptop ? 3 : 2}
        stroke="rgba(255, 255, 255, 0.60)"
      />
      <circle
        cx={
          circlePosition === CirclePosition.LEFT
            ? points[points.length - 1].x - radius
            : points[0].x + radius
        }
        cy={
          circlePosition === CirclePosition.LEFT
            ? points[points.length - 1].y
            : points[0].y
        }
        r={radius}
        fill="rgba(255, 255, 255, 0.60)"
      />
    </svg>
  );
};

export default Line;
