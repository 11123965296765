import { type FC, useState } from "react";
import SideTitleSection from "../common/SideTitleSection/SideTitleSection";
import Text from "./Text/Text";
import Line from "./Line/Line";
import Circle from "./Circle/Circle";
import {
  type TTextType,
  ProductType,
  CirclePosition,
  ScreenSize,
} from "./Product.types";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./Product.module.scss";
import QuoteBlock from "../common/QuoteBlock/QuoteBlock";
import GoldBorderText from "../common/GoldBorderText/GoldBorderText";

const Product: FC = () => {
  const [selectedText, setSelectedText] = useState<TTextType>(
    ProductType.DESIGN,
  );
  const { width } = useWindowDimensions();

  const isMobile = width <= ScreenSize.MOBILE;
  const isTabletOrLaptop = width > ScreenSize.MOBILE;
  const isLaptop = width > ScreenSize.TABLET;

  const onCircleClickHandler = (type: TTextType): void => {
    if (selectedText === type) {
      setSelectedText(undefined);
    } else {
      setSelectedText(type);
    }
  };
  const quoteBlockTextSetup = (): JSX.Element => {
    if (isMobile)
      return (
        <h2 className={styles.product__quote}>
          ARC не просто колонки, это искусство звука и дизайна, отражение вашей
          <br />
          индивидуальности
        </h2>
      );
    if (isLaptop)
      return (
        <h2 className={styles.product__quote}>
          ARC не просто колонки, а уникальные произведения искусства звука и
          дизайна, которые отражают вашу неповторимую <br />
          <GoldBorderText text="индивидуальность" />
        </h2>
      );
    return (
      <h2 className={styles.product__quote}>
        ARC не просто колонки, это искусство звука и дизайна, отражение вашей
        <br />
        <GoldBorderText text="индивидуальности" />
      </h2>
    );
  };
  return (
    <>
      <SideTitleSection title="Продукт">
        <section className={styles.product} id="product">
          <Text
            type={ProductType.BUILD}
            isSelected={selectedText === ProductType.BUILD}
            isMobile={isMobile}
          >
            высококачественная сборка
          </Text>
          <Text
            type={ProductType.DESIGN}
            isSelected={selectedText === ProductType.DESIGN}
            isMobile={isMobile}
          >
            эксклюзивный дизайн
          </Text>
          <Text
            type={ProductType.SOUND}
            isSelected={selectedText === ProductType.SOUND}
            isMobile={isMobile}
          >
            чистое и глубокое звучание за счет использования кроссоверов
          </Text>
          <Text
            type={ProductType.ABSORPTION}
            isSelected={selectedText === ProductType.ABSORPTION}
            isMobile={isMobile}
          >
            поглощение нежелательной звуковой энергии, через демпфирование
            корпуса
          </Text>
          <Text
            type={ProductType.COMPONENTS}
            isSelected={selectedText === ProductType.COMPONENTS}
            isMobile={isMobile}
          >
            компоненты профессионального уровня для первоклассного звучания
          </Text>
          {isTabletOrLaptop && (
            <>
              <Line
                points={
                  isLaptop
                    ? [
                        { x: (width * 40) / 100, y: 620 },
                        { x: (width * 27) / 100, y: 430 },
                        { x: (width * 13.7) / 100, y: 430 },
                      ]
                    : [
                        { x: (width * 41.5) / 100, y: 284 },
                        { x: (width * 14.4) / 100, y: 284 },
                      ]
                }
                circlePosition={CirclePosition.LEFT}
                isLaptop={isLaptop}
              />
              <Line
                points={
                  isLaptop
                    ? [
                        { x: (width * 58.8) / 100, y: 368 },
                        { x: (width * 62) / 100, y: 120 },
                        { x: (width * 51.5) / 100, y: 120 },
                      ]
                    : [
                        { x: (width * 41.5) / 100, y: 182 },
                        { x: (width * 41.5) / 100, y: 102 },
                        { x: (width * 19.5) / 100, y: 102 },
                      ]
                }
                circlePosition={CirclePosition.LEFT}
                isLaptop={isLaptop}
              />
              <Line
                points={
                  isLaptop
                    ? [
                        { x: (width * 93) / 100, y: 1182 },
                        { x: (width * 61.5) / 100, y: 1182 },
                        { x: (width * 50.5) / 100, y: 520 },
                      ]
                    : [
                        { x: (width * 89.5) / 100, y: 222 },
                        { x: (width * 51.1) / 100, y: 222 },
                      ]
                }
                circlePosition={CirclePosition.RIGHT}
                isLaptop={isLaptop}
              />
              <Line
                points={
                  isLaptop
                    ? [
                        { x: (width * 93) / 100, y: 530 },
                        { x: (width * 76) / 100, y: 530 },
                        { x: (width * 61.5) / 100, y: 604 },
                      ]
                    : [
                        { x: (width * 90.5) / 100, y: 486 },
                        { x: (width * 56.1) / 100, y: 486 },
                        { x: (width * 56.1) / 100, y: 405 },
                      ]
                }
                circlePosition={CirclePosition.RIGHT}
                isLaptop={isLaptop}
              />
              <Line
                points={
                  isLaptop
                    ? [
                        { x: (width * 49.5) / 100, y: 830 },
                        { x: (width * 30) / 100, y: 1002 },
                        { x: (width * 12.5) / 100, y: 1002 },
                      ]
                    : [
                        { x: (width * 48.8) / 100, y: 425 },
                        { x: (width * 38.5) / 100, y: 425 },
                        { x: (width * 38.5) / 100, y: 486 },
                        { x: (width * 9.7) / 100, y: 486 },
                      ]
                }
                circlePosition={CirclePosition.LEFT}
                isLaptop={isLaptop}
              />
            </>
          )}
          {isMobile && (
            <>
              <Circle
                type={ProductType.BUILD}
                isSelected={selectedText === ProductType.BUILD}
                onClick={onCircleClickHandler}
              />
              <Circle
                type={ProductType.DESIGN}
                isSelected={selectedText === ProductType.DESIGN}
                onClick={onCircleClickHandler}
              />
              <Circle
                type={ProductType.SOUND}
                isSelected={selectedText === ProductType.SOUND}
                onClick={onCircleClickHandler}
              />
              <Circle
                type={ProductType.ABSORPTION}
                isSelected={selectedText === ProductType.ABSORPTION}
                onClick={onCircleClickHandler}
              />
              <Circle
                type={ProductType.COMPONENTS}
                isSelected={selectedText === ProductType.COMPONENTS}
                onClick={onCircleClickHandler}
              />
            </>
          )}
        </section>
      </SideTitleSection>
      <QuoteBlock>{quoteBlockTextSetup()}</QuoteBlock>
    </>
  );
};

export default Product;
