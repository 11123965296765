import { type FC } from "react";
import { type TTextType, CircleColor } from "../Product.types";
import styles from "./Circle.module.scss";

interface ICircleProps {
  type: TTextType;
  isSelected: boolean;
  onClick: (type: TTextType) => void;
}

const Circle: FC<ICircleProps> = ({ type, isSelected, onClick }) => {
  const onClickHandler = (): void => {
    onClick(type);
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      className={`${styles.circle} ${styles[`circle_type_${type}`]}`}
      onClick={onClickHandler}
    >
      <circle
        cx="15"
        cy="15"
        r="10"
        fill={isSelected ? CircleColor.SELECTED : CircleColor.ORDINARY}
        stroke="#E0A51F"
      />
    </svg>
  );
};

export default Circle;
