import { type FC } from "react";

interface TelegramIconProps {
  className?: string;
}

const TelegramIcon: FC<TelegramIconProps> = ({ className }) => (
  <svg
    className={className}
    width="81"
    height="81"
    viewBox="0 0 87 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87 2.72773L73.2383 73.7944C73.2383 73.7944 71.3128 78.7217 66.0234 76.3586L34.2715 51.4205L34.1242 51.347C38.4132 47.4021 71.6715 16.7709 73.1251 15.3824C75.3753 13.2321 73.9783 11.9519 71.3657 13.5763L22.239 45.534L3.28603 39.0017C3.28603 39.0017 0.303389 37.9149 0.0164514 35.5518C-0.274261 33.1849 3.38419 31.9047 3.38419 31.9047L80.6496 0.855821C80.6496 0.855821 87 -2.00232 87 2.72773Z"
      fill="black"
    />
  </svg>
);

TelegramIcon.defaultProps = { className: "" };

export default TelegramIcon;
