interface ConstsType {
  submitDefault: string;
  submitError: string;
  submitSuccess: string;
  submitAwait: string;
}

const consts: ConstsType = {
  submitDefault: "ОСТАВИТЬ ЗАЯВКУ",
  submitError: "ЧТО-ТО ПОШЛО НЕ ТАК :(",
  submitSuccess: "ЗАЯВКА ОТПРАВЛЕНА",
  submitAwait: "ЗАЯВКА ОТПРАВЛЯЕТСЯ...",
};

export default consts;
