// Преобразование градусов в радианы
const degToRad = (deg: number): number => (deg * Math.PI) / 180;

const drawWave = (
  ctx: CanvasRenderingContext2D,
  degAngle: number,
  waveStart: number,
  waveWidth: number,
  waveHeight: number,
  horizontal: boolean,
): void => {
  const { width, height } = ctx.canvas.getBoundingClientRect();

  const viewPortWidth = waveWidth;
  const viewPortHeight = waveHeight;
  const viewPortX = width - viewPortWidth;
  const viewPortY = waveStart;

  const frameTopOffset = waveWidth === 200 ? 26 : 50;
  const frameSideOffset = waveWidth === 200 ? 40 : 50;
  // толщина волны
  const waveWeight: number = 5;
  // цвет волны
  const waveColor: string = "#E0A51F";

  // количество отображаемых периодов гармонической функции
  const periods: number = 0.8;
  // текущий угол в радианах
  const radAngle: number = degToRad(degAngle);
  // координата верхней и нижней точек начала волны внутри canvas по горизонтали
  const start: number = (waveWidth * 4) / 5;
  // макс амплитуда волны в пкс
  const ampMax: number = waveWidth;
  // Шаг в градусах между соседними точками
  const step: number = 1;
  // Количество точек внутри canas для расчёта волны
  const pointsNumber: number = Math.floor((periods * 360) / step);
  // Массив с координатами x всех точек волны внутри canvas относительно start
  const dots: number[] = new Array(pointsNumber).fill(0);

  // Расчёт координат всех точек волны для текущего угла radAngle
  const current = dots
    .map((el, i) => {
      let amp: number = ampMax;
      // уменьшение амплитуды волны при приближении к верхней и нижней начальным точкам
      amp = ampMax * Math.sin(degToRad((i * step) / (2 * periods))) ** 4;
      // формула расчёта координаты точки волны
      const currentX: number =
        amp *
        Math.sin(degToRad((i * step) / 2) + radAngle) *
        Math.cos(3 * degToRad((i * step) / 2) + 3 * radAngle) *
        Math.sin(2 * degToRad((i * step) / 2) + 2 * radAngle);
      return currentX;
    })
    .reverse();

  ctx.lineWidth = waveWeight;
  ctx.strokeStyle = waveColor;
  ctx.clearRect(0, 0, width, height);

  if (!horizontal) {
    ctx.beginPath();

    ctx.arc(frameSideOffset, frameTopOffset, 10, 0, 2 * Math.PI, false);
    ctx.fillStyle = waveColor;
    ctx.fill();

    ctx.moveTo(frameSideOffset, frameTopOffset);
    ctx.lineTo(viewPortX + start, frameTopOffset);
    ctx.lineTo(viewPortX + start, 0 + viewPortY);

    // рисуем волну отрезками от точки к точке
    current.forEach((el, i) => {
      ctx.lineTo(
        viewPortX + el + start,
        (viewPortHeight / dots.length) * i + viewPortY,
      );
    });

    ctx.lineTo(viewPortX + start, viewPortY + viewPortHeight);
    ctx.lineTo(viewPortX + start, height);
    ctx.stroke();
  } else {
    ctx.beginPath();
    ctx.moveTo(0, start);
    // рисуем волну отрезками от точки к точке
    current.forEach((el, i) => {
      ctx.lineTo((width / dots.length) * i, el + start);
    });

    ctx.lineTo(width, start);
    ctx.stroke();
  }
};

export default drawWave;
