import { type FC } from "react";
import styles from "./GoldBorderText.module.scss";

interface GoldBorderTextProps {
  text: string;
  style?: React.CSSProperties;
}

const GoldBorderText: FC<GoldBorderTextProps> = ({ text, style }) => {
  return (
    <span style={style} className={styles.goldBorderText}>
      {text}
    </span>
  );
};

GoldBorderText.defaultProps = {
  style: {},
};

export default GoldBorderText;
