import React, { useState, type FC, useCallback } from "react";
import { motion } from "framer-motion";
import styles from "./Questions.module.scss";
import Question from "../Question/Question";
import { type QuestionType } from "../../../types/QuestionType";

interface QuestionsProps {
  questions: QuestionType[];
  getQuestionWidth?: (question: QuestionType) => string;
  customQuestionsClass?: string;
  customQuestionTitleClass?: string;
  customAnswerClass?: string;
  alwaysGoldLines?: boolean;
  hasHighlightAnimation?: boolean;
}

const ANIMATION_TIME = 100;

const Questions: FC<QuestionsProps> = ({
  questions,
  getQuestionWidth,
  customQuestionsClass,
  customQuestionTitleClass,
  customAnswerClass,
  alwaysGoldLines,
  hasHighlightAnimation,
}) => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null,
  );
  const [isAnimating, setIsAnimating] = useState(true);

  const handleQuestionClick = useCallback((index: number) => {
    setOpenQuestionIndex((prevIndex) => {
      const newIndex = prevIndex === index ? null : index;
      setIsAnimating(newIndex === null);
      return newIndex;
    });
  }, []);

  return (
    <ul className={`${styles.questions} ${customQuestionsClass}`}>
      {questions.map((question) => (
        <li
          key={question.index}
          style={{
            width: getQuestionWidth ? getQuestionWidth(question) : "100%",
            zIndex: openQuestionIndex === question.index ? 2 : "",
          }}
        >
          {hasHighlightAnimation ? (
            <Blink
              delay={question.index * ANIMATION_TIME}
              isAnimating={isAnimating}
            >
              <Question
                question={question.question}
                answer={question.answer}
                isOpen={openQuestionIndex === question.index}
                onClick={() => {
                  handleQuestionClick(question.index);
                }}
                customQuestionTitleClass={customQuestionTitleClass}
                alwaysGoldLine={alwaysGoldLines}
              />
            </Blink>
          ) : (
            <Question
              question={question.question}
              answer={question.answer}
              isOpen={openQuestionIndex === question.index}
              onClick={() => {
                handleQuestionClick(question.index);
              }}
              customQuestionTitleClass={customQuestionTitleClass}
              alwaysGoldLine={alwaysGoldLines}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

interface BlinkProps {
  children: React.ReactNode;
  delay: number;
  isAnimating: boolean;
}

const Blink: React.FC<BlinkProps> = ({ children, delay, isAnimating }) => {
  const animation = isAnimating
    ? {
        opacity: [1, 0.3, 1],
        transition: {
          duration: 0.5,
          ease: "easeInOut",
          repeat: Infinity,
          delay: delay / 1000,
          repeatDelay: 4,
        },
      }
    : {};

  return (
    <motion.div animate={animation} initial={{ opacity: 1 }}>
      {children}
    </motion.div>
  );
};

Questions.defaultProps = {
  getQuestionWidth: (question: QuestionType) => "100%",
  customQuestionsClass: "",
  customQuestionTitleClass: "",
  customAnswerClass: "",
  alwaysGoldLines: false,
  hasHighlightAnimation: false,
};

export default Questions;
