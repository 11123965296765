import React, { useRef, type FC } from "react";
import styles from "./Question.module.scss";
import useOutsideClick from "../../../hooks/useOutside";

interface QuestionProps {
  question: string;
  answer?: string;
  isOpen: boolean;
  onClick: () => void;
  customQuestionTitleClass?: string;
  customAnswerClass?: string;
  alwaysGoldLine?: boolean;
  answerStretched?: boolean; // Окно ответа растянуть на ширину экрана
}

const Question: FC<QuestionProps> = ({
  question,
  answer,
  isOpen,
  onClick,
  customQuestionTitleClass,
  customAnswerClass,
  alwaysGoldLine,
  answerStretched,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const refsToCheck = [ref];

  useOutsideClick(refsToCheck, () => {
    if (isOpen) {
      onClick();
    }
  });

  return (
    <div
      className={styles.question}
      onKeyDown={onClick}
      onClick={onClick}
      ref={ref}
      tabIndex={0}
      role="button"
    >
      <p className={`${styles.question__title} ${customQuestionTitleClass}`}>
        {question}
      </p>

      <div
        className={`${styles.question__line} ${
          isOpen || alwaysGoldLine ? styles.question__line_gold : ""
        }`}
      >
        <div
          className={`${styles.question__answer} ${
            isOpen ? styles.question__answer_opened : ""
          } ${
            answerStretched ? styles.question__answer_stretched : ""
          }          
          `}
        >
          <p className={`${styles.question__answer_text} ${customAnswerClass}`}>
            {answer}
          </p>
        </div>
      </div>
    </div>
  );
};

Question.defaultProps = {
  answer: "",
  customQuestionTitleClass: "",
  customAnswerClass: "",
  alwaysGoldLine: false,
  answerStretched: false,
};

export default Question;
