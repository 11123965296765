import React, { type FC } from "react";
import styles from "./QuestionsBlock.module.scss";
import GoldBorderTitle from "../common/GoldBorderTitle/GoldBorderTitle";
import Questions from "./Questions/Questions";
import questions from "../../data/Questions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { type QuestionType } from "../../types/QuestionType";

const QuestionsBlock: FC = () => {
  const { width: windowWidth } = useWindowDimensions();

  const getQuestionWidth = (question: QuestionType): string => {
    const width = windowWidth >= 769 ? question.line : question.tablet_line;
    return windowWidth <= 500 || !width ? "100%" : width;
  };

  return (
    <div className={styles.questionsBlock} id="FAQ">
      <GoldBorderTitle
        title="FAQ"
        titleClassName={styles.questionsBlock__title}
        headerClassName={styles.questionsBlock__header}
      />
      <Questions questions={questions} getQuestionWidth={getQuestionWidth} />
    </div>
  );
};

export default QuestionsBlock;
