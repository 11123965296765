import { type FC } from "react";
import styles from "./QuoteBlock.module.scss";

interface QuoteBlockProps {
  sectionClassName?: string;
  whiteTheme?: boolean;
  children: React.ReactNode;
}

const QuoteBlock: FC<QuoteBlockProps> = ({
  sectionClassName,
  whiteTheme,
  children,
}) => {
  return (
    <div
      className={`${styles.quoteBlock} ${sectionClassName} ${
        whiteTheme ? styles.quoteBlock_white : styles.quoteBlock_dark
      }`}
    >
      {children}
    </div>
  );
};

export default QuoteBlock;

QuoteBlock.defaultProps = {
  whiteTheme: false,
  sectionClassName: "",
};
