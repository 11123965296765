import { type FC } from "react";
import styles from "./SideTitleSection.module.scss";

interface ISideTitleSectionProps {
  title: string;
  children: React.ReactNode;
  titleClassName?: string;
}

const SideTitleSection: FC<ISideTitleSectionProps> = ({
  title,
  titleClassName,
  children,
}) => {
  return (
    <section className={styles.container}>
      <h3
        className={
          titleClassName !== undefined && titleClassName !== ""
            ? `${styles.container__title} ${titleClassName}`
            : `${styles.container__title}`
        }
      >
        {title}
      </h3>
      {children}
    </section>
  );
};

export default SideTitleSection;

SideTitleSection.defaultProps = {
  titleClassName: undefined,
};
