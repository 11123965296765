import { useState, useEffect } from "react";

export interface TWindowDimensions {
  width: number;
  height: number;
}

const getWindowDimensions = (): TWindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function useWindowDimensions(): TWindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<TWindowDimensions>(
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
}
