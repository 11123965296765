import { type FC, useState, useEffect, useRef, Fragment, useMemo } from "react";
import style from "./Header.module.scss";
import { Logo, MenuIco } from "../../assets/icons";
import MENU_ITEMS from "../../data/Menu-items";
import cn from "../../utils/cn";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useYOffset from "../../hooks/useYOffset";
import useOutsideClick from "../../hooks/useOutside";

const switchToDesktopRes = 1260;
const switchToMobileRes = 500;

const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuIcoActive, setMenuIcoActive] = useState<boolean>(true);
  const { width } = useWindowDimensions();
  const burgerScreenWidth: boolean = useMemo(
    () => width <= switchToDesktopRes,
    [width],
  );
  const mobileScreenWidth: boolean = useMemo(
    () => width <= switchToMobileRes,
    [width],
  );
  const { yOffset } = useYOffset();
  const [mobileMenuOn, setMobileMenuOn] = useState(true);

  const ref = useRef<HTMLDivElement | null>(null);

  const refsToCheck = [ref];

  useOutsideClick(refsToCheck, () => {
    if (menuOpen) {
      closeMenu();
    }
  });

  const openMenu = (): void => {
    if (menuIcoActive) {
      setMenuOpen(true);
      setMenuIcoActive(false);
    }
  };

  const closeMenu = (): void => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (mobileScreenWidth) {
      setMobileMenuOn(false);
      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        setMobileMenuOn(true);
      }, 100);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [mobileScreenWidth]);

  useEffect(() => {
    if (mobileScreenWidth && menuOpen) {
      document.body.classList.add("no_scroll");
    } else {
      document.body.classList.remove("no_scroll");
    }
  }, [menuOpen, width]);

  useEffect(() => {
    if (!menuOpen) {
      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        setMenuIcoActive(true);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [menuOpen]);

  return (
    <header className={style.header} id="header">
      <div className={style.header__container}>
        <div className={style.header__top_content}>
          <div className={style.header__logo_section}>
            <a href="/" className={style.header__logo_link}>
              <Logo className={style.header__logo_ico} />
              <h1 className={style.header__logo_title}>
                custom acoustic system
              </h1>
            </a>
          </div>
          <div
            className={style.header__menu}
            onMouseEnter={() => {
              if (!burgerScreenWidth && menuIcoActive) {
                openMenu();
              }
            }}
            onClick={() => {
              menuIcoActive && openMenu();
            }}
            aria-hidden="true"
            ref={ref}
          >
            <div
              className={style.header__menu_container}
              style={{
                top:
                  mobileScreenWidth &&
                  yOffset &&
                  yOffset > document.body.scrollHeight - window.innerHeight - 50
                    ? `${document.body.scrollHeight - 100}px`
                    : `${yOffset}px`,
                display: mobileMenuOn ? "flex" : "none",
              }}
            >
              <MenuIco className={style.header__menu_ico} />
              <span
                className={cn(
                  style.header__menu_text,
                  menuOpen ? style.header__menu_text_invisible : "",
                )}
              >
                меню
              </span>
            </div>
            <div
              className={cn(
                "menu",
                burgerScreenWidth
                  ? style.header__menu_burger
                  : style.header__menu_block,
                menuOpen &&
                  (burgerScreenWidth
                    ? style.header__menu_burger_visible
                    : style.header__menu_block_visible),
              )}
            >
              {width <= switchToDesktopRes && (
                <button
                  type="button"
                  className={style.header__menu_burger_button}
                  aria-label="закрыть меню"
                  onClick={closeMenu}
                >
                  <MenuIco
                    className={cn(
                      style.header__menu_ico,
                      style.header__menu_ico_burger,
                    )}
                  />
                </button>
              )}
              {MENU_ITEMS.map((el, i) => (
                <Fragment key={el.id}>
                  {width <= switchToDesktopRes && (
                    <div className={style.header__menu_burger_line} />
                  )}
                  <a
                    href={el.link}
                    className={style.header__menu_link}
                    onClick={closeMenu}
                  >
                    {el.name}
                  </a>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <h2 className={style.header__slogan}>
          ЗВУК <span className={style.header__slogan_accent}>ОЖИВАЮЩИЙ</span> В
          ИСКУССТВЕ
        </h2>
      </div>
    </header>
  );
};

export default Header;
