import { type FC } from "react";
import styles from "./OrderSectionArticle.module.scss";
import cn from "../../utils/cn";

type SetRefCallbackFunc = (el: HTMLHeadingElement | null) => void;

interface OrderSectionArticleProps {
  title: string;
  text: string;
  preLine: boolean;
  textAlignRight: boolean;
  refItemTitle: SetRefCallbackFunc;
}

const OrderSectionArticle: FC<OrderSectionArticleProps> = ({
  title,
  text,
  preLine,
  textAlignRight,
  refItemTitle,
}) => {
  return (
    <article
      className={cn(
        styles.orderSectionArticle,
        textAlignRight
          ? styles.orderSectionArticle_right
          : styles.orderSectionArticle_left,
      )}
    >
      <h4
        className={cn(
          styles.orderSectionArticle__title,
          textAlignRight
            ? styles.orderSectionArticle__title_right
            : styles.orderSectionArticle__title_left,
        )}
        ref={refItemTitle}
      >
        {title}
      </h4>
      <p
        className={cn(
          styles.orderSectionArticle__text,
          preLine ? styles.orderSectionArticle__text_preLine : "",
          textAlignRight
            ? styles.orderSectionArticle__text_right
            : styles.orderSectionArticle__text_left,
        )}
      >
        {text}
      </p>
    </article>
  );
};

export default OrderSectionArticle;
