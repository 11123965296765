import { type FC } from "react";
import styles from "./ContactsSection.module.scss";
import Contacts from "./Contacts";

const ContactsSection: FC = () => {
  return (
    <section className={styles.contactsSection} id="contacts">
      <h2 className={styles.contactsSection__header}>контакты</h2>
      <Contacts />
    </section>
  );
};

export default ContactsSection;
