import { type FC } from "react";
import styles from "./GoldBorderTitle.module.scss";

interface GoldBorderTitleProps {
  title: string;
  titleClassName?: string;
  headerClassName: string;
}

const GoldBorderTitle: FC<GoldBorderTitleProps> = ({
  title,
  titleClassName,
  headerClassName,
}) => {
  return (
    <div className={`${styles.title} ${titleClassName}`}>
      <h3 className={headerClassName}>{title}</h3>
    </div>
  );
};

GoldBorderTitle.defaultProps = {
  titleClassName: "",
};

export default GoldBorderTitle;
