import { useEffect, useState } from "react";

interface TUseYOffset {
  yOffset: number;
}

const getYOffset = (): number => {
  return window.scrollY;
};

const useYOffset = (): TUseYOffset => {
  const [yOffset, setYOffset] = useState<number>(getYOffset);

  useEffect(() => {
    const handleScroll = (): void => {
      setYOffset(getYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return { yOffset };
};

export default useYOffset;
