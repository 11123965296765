import { type FC } from "react";

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93"
    height="120"
    className={className}
    viewBox="0 0 93 120"
    fill="none"
  >
    <g clipPath="url(#clip0_45_9)">
      <path
        d="M14.5512 13.7243H1.64862C0.73917 13.7243 0 14.4571 0 15.3587V118.366C0 119.267 0.73917 120 1.64862 120H6.16104C7.07049 120 7.80966 119.267 7.80966 118.366V51.2521C7.80966 50.3504 8.54883 49.6176 9.45828 49.6176H12.9026C13.812 49.6176 14.5512 50.3504 14.5512 51.2521V76.4868C14.5512 77.3884 15.2904 78.1212 16.1998 78.1212H20.7122C21.6217 78.1212 22.3609 77.3884 22.3609 76.4868V15.3587C22.3609 14.4571 21.6217 13.7243 20.7122 13.7243H14.5512ZM12.9026 42.9339H9.45828C8.54883 42.9339 7.80966 42.201 7.80966 41.2994V22.3993C7.80966 21.4976 8.54883 20.7648 9.45828 20.7648H12.9026C13.812 20.7648 14.5512 21.4976 14.5512 22.3993V41.2994C14.5512 42.201 13.812 42.9339 12.9026 42.9339Z"
        fill="black"
      />
      <path
        d="M85.1902 0H72.6321C71.7265 0 70.9912 0.728993 70.9912 1.62681V76.4944C70.9912 77.3922 71.7265 78.1212 72.6321 78.1212H91.3552C92.2607 78.1212 92.996 77.3922 92.996 76.4944V51.2444C92.996 50.3466 92.2607 49.6176 91.3552 49.6176H86.8311C85.9255 49.6176 85.1902 50.3466 85.1902 51.2444V69.8069C85.1902 70.7047 84.4549 71.4337 83.5494 71.4337H80.4418C79.5362 71.4337 78.8009 70.7047 78.8009 69.8069V9.0165C78.8009 8.11868 79.5362 7.38969 80.4418 7.38969H83.5494C84.4549 7.38969 85.1902 8.11868 85.1902 9.0165V40.9541C85.1902 41.8519 85.9255 42.5809 86.8311 42.5809H91.3552C92.2607 42.5809 92.996 41.8519 92.996 40.9541V1.62681C92.996 0.728993 92.2607 0 91.3552 0H85.1864H85.1902Z"
        fill="black"
      />
      <path
        d="M58.6111 49.6176V1.63064C58.6111 0.728993 57.8758 0 56.9663 0H37.7595C36.85 0 36.1147 0.728993 36.1147 1.63064V83.5311C36.1147 84.4328 36.85 85.1618 37.7595 85.1618H42.2797C43.1891 85.1618 43.9244 84.4328 43.9244 83.5311V51.2482C43.9244 50.3466 44.6597 49.6176 45.5692 49.6176H49.4972C50.2596 49.6176 50.9252 50.1394 51.0994 50.8761C55.3757 69.0165 59.656 87.1569 63.9323 105.297C64.1142 106.065 64.8263 106.598 65.6196 106.556C66.7652 106.494 67.9107 106.437 69.0601 106.375C70.074 106.322 70.7977 105.386 70.581 104.403C66.591 86.1402 62.601 67.8808 58.6111 49.6176ZM43.9244 41.2994V9.02034C43.9244 8.11868 44.6597 7.38969 45.5692 7.38969H49.1566C50.0661 7.38969 50.8014 8.11868 50.8014 9.02034V41.3032C50.8014 42.2049 50.0661 42.9339 49.1566 42.9339H45.5692C44.6597 42.9339 43.9244 42.2049 43.9244 41.3032V41.2994Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_45_9">
        <rect width="93" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Logo.defaultProps = { className: "" };

export default Logo;
